<template>
  <div class="header-bar">
    <div  class="header-controls" >
      <div class="brand-name" @click="navigate('Home')">Wyst</div>
      <small class="beta-label">(Beta)</small>
      <div style="margin: 5px 0 0 30px;">
        <SessionFileMenu />
      </div>
      <NewVersionBanner />
    </div>

    <div v-if="isAuthenticated" style="align-self: center; margin-left:-50px">    <ModelMenu /></div>

    <div>
    <div v-if="isAuthenticated" class="profile-container" @click="toggleProfileDropdown">
      <ProfileMenu v-if="isAuthenticated" @logout="logout"/>
    </div>
    <button v-else @click="login" class="auth-button">Log in</button>
  </div>
  </div>
</template>

<script>
  import { store } from '@/services/store';
  import { useAuth0 } from '@auth0/auth0-vue';
  import SessionFileMenu from './SessionFileMenu.vue';
  import ProfileMenu from './ProfileMenu.vue';
  import NewVersionBanner from './NewVersionBanner.vue';
  import ModelMenu from './ModelMenu.vue';
  
  export default {
    setup() {
      const { user, loginWithRedirect, logout, isAuthenticated } = useAuth0();

      return {
        login: () => {
          loginWithRedirect();
        },
        logout: () => {
          logout({ logoutParams: { returnTo: window.location.origin } });
        },
        isAuthenticated,
        user
      };
    },
  data() {
    return {
      showProfileDropdown: false,
      store,
    }
  },
  components: {
    SessionFileMenu,
    ProfileMenu,
    ModelMenu,
    NewVersionBanner
  },
    methods: {
      setCurrentAppView(view) {
        store.currentAppView = view;
        },
      navigate(routeName) {
        this.$router.push({ name: routeName});
    },
      redirectToHowToUse() {
        window.open('https://docs.usemynt.com/how-to-use', '_blank');
      },
      toggleProfileDropdown() {
        this.showProfileDropdown = !this.showProfileDropdown;
      },
    buyMoreCredits() {
        // Logic to buy more credits
        // This could redirect to a payment page or open a modal for payment
      },
      userInitials() {
        return this.user.name.split(' ').map(name => name[0]).join('') ;
      },
    
    async mounted() {
      if (this.isAuthenticated) {
        await this.fetchAccountCredits();
      }
    },
    }
  };
</script>
<style>
.dropdown-menu {
  position: absolute;
    top: 46px;
    background-color: #f9f9f9;
    min-width: 131px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
    z-index: 1001;
    font-size: 15px;
    border-radius: 0 0 10px 10px;
}


.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 190px;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.header-bar {
  position: relative;
  box-sizing: border-box;
  padding: 7px 20px;
  background-color: #3c7e6dd9;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  height: 46px;
}

.profile-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.brand-name {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.5em;
  font-weight: bold;
  margin-top: -5px;
}

.beta-label {
  position: absolute;
    left: 54px;
    font-size: xx-small;
    top: 31px;

}
.header-controls {
  display: flex;
  align-items: center;
}

.toggle-switch {
  display: flex;
  border: 2px solid white;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
}

.toggle-option {
  padding: 5px 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.toggle-option.active {
  color: #3c7e6dd9;
  background: white;
}

.toggle-option:not(.active) {
  color: white;
  background-color: #3c7e6dd9;
}

.toggle-option:hover:not(.active) {
  background-color: #e4e9f0;
}
.profile-dropdown {
  position: absolute;
  top: 100%;
  right: -15px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 100;
  padding: 10px;
  width: 200px; 
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  color: black;
}

.profile-dropdown-item {
      padding: 10px 5px;
      cursor: pointer;
      width: 190px;
      text-decoration: none;
      color: black
    }
    
    .profile-dropdown-item:hover {
      background-color: #e4e9f0;
    }

    .dropdown-item:hover {
      background-color: #e4e9f0;
    }


.profile-dropdown-item:not(:last-child) {
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s ease;
  padding-bottom: 15px; 
}

.auth-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: white;
  color: #3c7e6dd9;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-button:hover {
   background-color: #e4e9f0;
}


</style>