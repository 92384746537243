<!-- NewVersionBanner.vue -->
<template>
  <div class="new-version-banner" @click="redirectToNewVersion">
    <div class="banner-content">
      <span class="new-badge">NEW</span>
      <span class="banner-text">Try the new version of mynt!</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewVersionBanner',
  methods: {
    redirectToNewVersion() {
      window.open('https://www.usemynt.com/app', '_blank');
    }
  }
}
</script>

<style scoped>
.new-version-banner {
  background-color: #2a5d50;
  padding: 4px 12px;
  border-radius: 20px;
  margin-left: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.new-version-banner:hover {
  background-color: #224a40;
}

.banner-content {
  display: flex;
  align-items: center;
}

.new-badge {
  background-color: #ff6b6b;
  color: white;
  font-size: 0.7em;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 10px;
  margin-right: 8px;
}

.banner-text {
  font-size: 0.85em;
  white-space: nowrap;
}
</style>